<template>
  <div class="col-sm-12">
    <ValidationObserver v-slot="{ passes }">
      <form class="form-auth-small" @submit.prevent="passes(sendbyCard)" id="registration" ref="registration" autocomplete="off">
        <div class="col-12">
            <div class="mt-2 pt-3 mb-2 pb-2">
                <h2 class="font-20">Your Personal Information</h2>
            </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="firstName" rules="required" v-slot="{ errors}">
                <div class="form-group" :class="{'errors': errors.length && !personalinfo.firstName,'success':personalinfo.firstName}">
                    <span class="float-label">
                        <input type="text" autocomplete="off" id="firstName" v-model="personalinfo.firstName" class="form-control" placeholder="First Name*">
                        <label for="firstName" class="control-label">First Name*</label>
                        <div class="line"></div>
                        <i class="fa fa-times" v-if="errors.length && !personalinfo.firstName"></i>
                        <i class="fa fa-check" v-if="personalinfo.firstName"></i>
                    </span>
                </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <span class="float-label">
                    <input type="text" autocomplete="off" v-model="personalinfo.middleName" class="form-control" id="middlename" placeholder="Middle Name">
                    <label for="middlename" class="control-label">Middle Name</label>
                    <div class="line"></div>
                </span>
            </div>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="lastName" rules="required" v-slot="{ errors}">
                <div class="form-group" :class="{'errors': errors.length && !personalinfo.lastName,'success':personalinfo.lastName}">
                    <span class="float-label">
                        <input type="text" autocomplete="off" id="lastName" v-model="personalinfo.lastName" class="form-control" placeholder="Last Name*">
                        <label for="lastName" class="control-label">Last Name*</label>
                        <div class="line"></div>
                        <i class="fa fa-times" v-if="errors.length && !personalinfo.lastName"></i>
                        <i class="fa fa-check" v-if="personalinfo.lastName"></i>
                    </span>
                </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="signin_email" rules="required|email" v-slot="{ errors}">
                <div class="form-group" :class="{'errors': errors.length && !personalinfo.email || errors[0],'success':personalinfo.email && !errors[0]}">
                    <span class="float-label">
                        <input type="email" v-model="personalinfo.email" class="form-control" name="email1" id="signin_email" placeholder="Email Address*">
                        <label for="signin_email" class="control-label">Email Address*</label>
                        <div class="line"></div>
                        <i class="fa fa-times" v-if="errors.length && !personalinfo.email || errors[0] "></i>
                        <i class="fa fa-check" v-if="personalinfo.email && !errors[0]"></i>
                    </span>
                </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="country" rules="required" v-slot="{ errors}">
              <div class="country-dropdown">
                  <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':personalinfo.country && personalinfo.country.length != 0  && !errors[0]}">
                      <span class="float-label">
                      <label for="Country">Country*</label>
                          <multiselect
                              placeholder=""
                              v-model="personalinfo.country" 
                              :show-labels="false" 
                              :options="usercountrysOptions" 
                              :searchable="true"
                              label="name" 
                              track-by="name"
                              @select="setUserPrimaryNumberCountryCode">
                              <template slot="option" slot-scope="props">
                                  <span :class="props.option.flagClass"></span>
                                  <div class="option__desc">
                                      <span class="option__title">{{ props.option.name }}</span>
                                  </div>
                              </template>
                          </multiselect>  
                          <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                          <i class="fa fa-check" v-if="personalinfo.country && personalinfo.country.length != 0 && !errors[0]"></i>
                      </span>
                  </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <div class="form-group" :class="{ errors: mobileInputError == false, success: personalinfo.mobile &&  mobileInputError, }" >
              <div class="float-label" v-if="usermobilestatus">
                <label for="user_number" class="control-label" >Mobile Number*</label>
                <vue-tel-input
                  v-model="personalinfo.mobile"
                  v-bind="userMobileNumber"
                  @validate="mobileInputValidate"
                  ref="telInputUser"
                  @keypress.native="onlyNumber($event)"
                >
                  <template v-slot:arrow-icon>
                    <span class="vti__dropdown-arrow">{{ "▼" }}</span>
                  </template>
                </vue-tel-input>
                <div class="line"></div>
                <i class="fa fa-times" v-if="mobileInputError == false" ></i>
                <i class="fa fa-check" v-if="mobileInputError && personalinfo.mobile" ></i>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="address1" rules="required" v-slot="{ errors}">
              <div class="form-group address-picker" :class="{'errors': errors.length && !personalinfo.address1,'success':personalinfo.address1}">
                <span class="float-label">
                  <input type="text" v-if="queryAddress1==true" v-model="personalinfo.address1" class="form-control" id="address" placeholder="Address*" v-on:keyup="loqateAddress($event,'address')" @input="capitalizeFirstLetter($event,'address1')">
                  <input type="text" v-if="queryAddress1==false" v-model="personalinfo.address1" class="form-control" id="address" placeholder="Address*">
                  <label for="address" class="control-label">Address Line 1*</label>
                  <div class="line"></div>
                  <i class="fa fa-times" v-if="errors.length && !personalinfo.address1"></i>
                  <i class="fa fa-check" v-if="personalinfo.address1"></i>
                </span>
                <span class="text-danger error-msg" v-if="locatefieldName == 'address'">{{fieldErrorMsg}}</span>
                <ul v-if="addressData!='' && fieldType=='address'">
                  <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                    <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                  </li>    
                </ul>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <span class="float-label">
                <input type="text" autocomplete="off" v-model="personalinfo.address2" class="form-control" id="address2" placeholder="Address Line 2(Optional)">
                <label for="address2" class="control-label">Address Line 2</label>
                <div class="line"></div>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="city" rules="required" v-slot="{ errors}">
              <div class="form-group address-picker" :class="{'errors': errors.length && !personalinfo.city,'success':personalinfo.city}">
                <span class="float-label">
                    <input type="text" v-if="queryCity==true" v-model="personalinfo.city" class="form-control" id="city" placeholder="City*" v-on:keyup="loqateAddress($event,'city')" @input="capitalizeFirstLetter($event,'city')">
                    <input type="text" v-if="queryCity==false" v-model="personalinfo.city" class="form-control" id="city" placeholder="City*">
                    <label for="city" class="control-label">City*</label>
                    <div class="line"></div>
                    <i class="fa fa-times" v-if="errors.length && !personalinfo.city"></i>
                    <i class="fa fa-check" v-if="personalinfo.city"></i>
                </span>
                <span class="text-danger error-msg" v-if="locatefieldName == 'city'">{{fieldErrorMsg}}</span>
                <ul v-if="addressData!='' && fieldType=='city'">
                    <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                        <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                    </li>    
                </ul>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="scpr" rules="required" v-slot="{ errors}">
              <div class="form-group address-picker" :class="{'errors': errors.length && !personalinfo.scpr,'success':personalinfo.scpr}">
                <span class="float-label">
                  <input type="text" v-if="queryProvince==true" v-model="personalinfo.scpr" class="form-control" id="state" placeholder="Post Code*" v-on:keyup="loqateAddress($event,'state')" @input="capitalizeFirstLetter($event,'scpr')">
                  <input type="text" v-if="queryProvince==false" v-model="personalinfo.scpr" class="form-control" id="state" placeholder="Post Code*">
                  <label for="state" class="control-label">State/Province/Region*</label>
                  <div class="line"></div>
                  <i class="fa fa-times" v-if="errors.length && !personalinfo.scpr"></i>
                  <i class="fa fa-check" v-if="personalinfo.scpr"></i>
                </span>
                <ul v-if="addressData!='' && fieldType=='state'">
                  <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                    <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                  </li>    
                </ul>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
              <ValidationProvider name="postCode" rules="required" v-slot="{ errors}">
                <div class="form-group address-picker" :class="{'errors': errors.length && !personalinfo.postCode,'success':personalinfo.postCode}">
                  <span class="float-label">
                    <input type="text" v-if="queryZip==true" v-model="personalinfo.postCode" class="form-control" id="postCode" placeholder="Post Code*" v-on:keyup="loqateAddress($event,'postcode')">
                    <input type="text" v-if="queryZip==false" v-model="personalinfo.postCode" class="form-control" id="postCode" placeholder="Post Code*">
                    <label for="postCode" class="control-label">Post Code*</label>
                    <div class="line"></div>
                    <i class="fa fa-times" v-if="errors.length && !personalinfo.postCode"></i>
                    <i class="fa fa-check" v-if="personalinfo.postCode"></i>
                  </span>
                  <ul v-if="addressData!='' && fieldType=='postcode'">
                    <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                      <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                    </li>    
                  </ul>
                </div>
              </ValidationProvider>
          </div>
        </div>
        <div class="payment-option-card p-3">
          <h6>Payment Options</h6>
          <p>Enter your details below to use these methods of payment</p>
          <ul class="list-unstyled d-flex mb-0">
            <!-- <li><img src="@/assets/ecommerce/card-amex.png" /></li> -->
            <li class="mx-2"><img src="@/assets/ecommerce/card-visa.png" /></li>
            <li><img src="@/assets/ecommerce/card-master.png" /></li>
          </ul>
        </div>
        <h5 class="mb-4">Your Payment Details</h5>
        <!-- <ValidationObserver v-slot="{ passes }">
        <form class="form-auth-small" @submit.prevent="passes(sendbyCard)" id="registration" ref="registration" autocomplete="off"> -->
        <ValidationProvider name="full-nameabc" rules="required" v-slot="{ errors }" >
          <div class="form-group" :class="{ errors: (errors.length && !paybyCard.fullName) || (errorArr[2] && errorArr[2].fieldName != ''), success: paybyCard.fullName && errorArr[2] && errorArr[2].fieldName == ''}" >
            <span class="float-label">
              <input type="text" id="fullName" v-model="paybyCard.fullName" class="form-control" placeholder="Full Name as Displayed on Card*" @input="checkNameFormat($event, 'full_name', 'Full Name', 2)" />
              <label for="fullName" class="control-label">Full Name as Displayed on Card*</label>
              <div class="line"></div>
              <i class="fa fa-times" v-if="errors.length && !paybyCard.fullName && errorArr[2] && errorArr[2].fieldName == '' "></i>
              <i class="fa fa-check" v-if=" paybyCard.fullName && errorArr[2] && errorArr[2].fieldName == ''"></i>
            </span>
            <span class="text-danger error-msg" v-if="errorArr[2] && errorArr[2].fieldName != ''" >{{ errorArr[2].errorMessage }}</span >
            <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-0010'">{{apiErrorMsg}}</span>
          </div>
        </ValidationProvider>
        <div class="form-group">
          <span class="float-label">
            <input ref="cardNumInput" id="cardnumber" :data-error="cardErrors.cardNumber ? true : false" v-model="cardNumber" type="tel" class="form-control" placeholder="#### #### #### ####" v-cardformat:formatCardNumber @blur="validCardNumber" @paste.prevent/>
            <label for="cardnumber" class="control-label">Enter Card Number*</label>
            <div class="line"></div>
            <i :class="cardBrandClass" class="fa-lg" style="color: #1e9c7e"></i>
            <!--  style="color: #1e9c7e" -->
          </span>
          <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-001' || apiErrorCode == 'V0022-002'">{{apiErrorMsg}}</span>
          <span class="text-danger error-msg" v-if="cardErrors.cardNumber">{{cardErrors.cardNumber}}</span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <span class="float-label">
                <input ref="cardExpInput" id="card-exp" :data-error="cardErrors.cardExpiry ? true : false" v-model="cardExpiry" maxlength="10" class="form-control" v-cardformat:formatCardExpiry @blur="validExpireDate" @paste.prevent/>
                <label for="card-exp" class="control-label">Expiry Date(e.g-MM/YY)*</label>
              </span>
              <span class="text-danger error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</span>
              <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-003' || apiErrorCode == 'V0022-004' || apiErrorCode == 'V0022-005' || apiErrorCode == 'V0022-006' || apiErrorCode == 'V0022-007' || apiErrorCode == 'V0022-008' || apiErrorCode == 'V0022-009'">{{apiErrorMsg}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <span class="float-label">
                <input ref="cardCvcInput" id="card-cvc" :data-error="cardErrors.cardCvc ? true : false" v-model="cardCvc" class="form-control" v-cardformat:formatCardCVC @blur="validCvv" @paste.prevent/>
                <label for="card-cvc" class="control-label">CVV*</label>
              </span>
              <span class="text-danger error-msg" v-if="cardErrors.cardCvc">{{cardErrors.cardCvc}}</span>
              <span class="text-danger error-msg" v-if="apiErrorCode == 'V0022-0011' || apiErrorCode == 'V0022-0012'">{{apiErrorMsg}}</span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block" :disabled="btnDesabled">
          Pay by Card
        </button>
      </form>
    </ValidationObserver>

    <form id="3dsform" ref="threedsform" :action="ecompayurl" method="POST" :key="ecompaycreq + ecompaythreedsession">
      <input type="hidden" name="creq" :value="ecompaycreq"/>
      <input type="hidden" name="threeDSSessionData" :value="ecompaythreedsession" />
    </form>

    <iframe id="tdsMmethodTgtFrame" name="tdsMmethodTgtFrame" style="width: 1px; height: 1px; display: none;" src="javascript:false;" :key="DSMethodData + threeDSMethodData">  </iframe>
    <form id="tdsMmethodForm" ref="tdsMmethodForm" name="tdsMmethodForm" :action="ecompayurl" method="post" target="tdsMmethodTgtFrame" :key="DSMethodData + threeDSMethodData">
        <input type="hidden" name="3DSMethodData" :value="DSMethodData"/>
        <input type="hidden" name="threeDSMethodData" :value="threeDSMethodData"/>
    </form>
  </div>
</template>
<script>
const { ValidationObserver, ValidationProvider } = require("vee-validate");
// import { v4 as uuidv4 } from 'uuid';
// import shared from "@/shared.js";
import parsePhoneNumber from "libphonenumber-js";
const { VueTelInput } = require("vue-tel-input");

export default {
  name: "PaybyCard",
  components: {
    ValidationObserver,
    ValidationProvider,
    // Datepicker: () => import('vuejs-datepicker'),
    VueTelInput,
    Multiselect: () => import("vue-multiselect"),
  },
  props: {
    checkout_country: Object,
    // merchant_details: Object,
  },
  data() {
    return {
      btnDesabled:false,
      paybyCard: {
        fullName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        futureUseIs: true,
        currency: {
          name: "",//"GHS"
          flagClass: "", //"flag-icon flag-icon-gh fi-md fi-round",
        },
        amount: "",
        disabledCurrencyDropDown: true,
      },
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardErrors: {},
      // declaring card-brand in data{} enables card brand name/classes.
      cardBrand: null,

      // currencyOptions: [
      //   { name: "GBP", flagClass: "flag-icon flag-icon-gb fi-md fi-round" },
      //   { name: "GHS", flagClass: "flag-icon flag-icon-gh fi-md fi-round" },
      //   // { name: "USD", flagClass: "flag-icon flag-icon-us fi-md fi-round" },
      //   // { name: "RUB", flagClass: "flag-icon flag-icon-ru fi-md fi-round" },
      // ],

      // countrysOptions: [
      //   {
      //     name: "United Kingdom",
      //     flagClass: "flag-icon flag-icon-gb fi-xl fi-round",
      //     primaryNumberCode: "GB",
      //     id:"8e60ff5a-dda4-45eb-b220-9c4dfcdb32a5"
      //   },
      //   {
      //     name: "Ghana",
      //     flagClass: "flag-icon flag-icon-gh fi-xl fi-round",
      //     primaryNumberCode: "GH",
      //     id:"8ee2b557-1047-49c4-bac2-ee7bf35e24e3"
      //   },
      // ],

      errorArr: [],
      fieldName: "",
      fieldErrorMsg: "",
      apiErrorMsg: "",
      elementName: "",
      apiErrorCode: "",
      transactionId: "",
      paymenterrormsg: "",

      merchantdata:null,

      personalinfo:{
        email:'',
        mobile:'',
        country: '',
        firstName:'',
        middleName:'',
        lastName:'',
        address1:'',
        address2:'',
        city:'',
        scpr:'',
        postCode:'',
      },
      usermobilestatus:false,
      userMobileNumber: {
        // mode: "auto",
        // defaultCountry: "GH",
        // autoFormat: true,
        // dropdownOptions: {
        //   showFlags: true,
        //   disabled: true,
        //   showDialCodeInSelection: true,
        // },
      },
      usercountrysOptions:[],
      searchText:'',
      addressData:[],
      fieldType:'',
      locatefieldName:'',
      queryAddress1:true,
      queryCity:true,
      queryProvince:true,
      queryZip:true,
      mobileInputError:'',

      ecompayurl:'',
      DSMethodData:'',
      threeDSMethodData:'',
      
      ecompaycreq:'',
      ecompaythreedsession:''
    };
  },
  methods: {
    submit3DSForm() {
      console.log("3DS Form Values:", this.ecompaycreq, this.ecompaythreedsession);
      if (this.ecompaycreq && this.ecompaythreedsession) {
        this.$nextTick(() => {
          this.$refs.threedsform.submit();
        });
      }
    },

    submitTdsMmethodForm() {
      console.log("Tds Mmethod Form Values:", this.DSMethodData, this.threeDSMethodData );
      if (this.DSMethodData && this.threeDSMethodData) {
        this.$nextTick(() => {
          this.$refs.tdsMmethodForm.submit();
        });
      }
    },

    validCardNumber() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
        this.cardErrors.cardNumber = "Invalid Card Number.";
      } else {
        this.cardErrors.cardNumber = "";
      }
    },
    validExpireDate() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
        this.cardErrors.cardExpiry = "Invalid Expiration Date.";
      } else {
        this.cardErrors.cardExpiry = "";
      }
    },
    validCvv() {
      this.cardErrors = {};
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = "Invalid CVV.";
      } else {
        this.cardErrors.cardCvc = "";
      }
    },

    sendbyCard() {
      if (typeof this.mobileInputError == "undefined") {
        this.mobileInputError = false;
      }
      if (this.mobileInputError) {
        this.btnDesabled = true;
        this.updatepersonalinfo();
        // init
        this.cardErrors = {};

        // validate card number
        if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
          this.cardErrors.cardNumber = "Invalid Card Number.";
          this.btnDesabled = false;
        }

        // validate card expiry
        if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
          this.cardErrors.cardExpiry = "Invalid Expiration Date.";
          this.btnDesabled = false;
        }

        // validate card CVV
        if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
          this.cardErrors.cardCvc = "Invalid CVV.";
          this.btnDesabled = false;
        }

        this.createLog(this.transactionId, 'Call Checkout');
        const dob = this.cardExpiry.split("/");
        const getFullYear = new Date().getFullYear();
        const first2Digit = getFullYear.toString().slice(0, 2);

        const userdetail = JSON.parse(sessionStorage.getItem("userData"));
        const usermobileNumber = parsePhoneNumber(this.personalinfo.mobile, this.personalinfo.country.primaryNumberCode);

        let updateData = {
          is_token_type:false,
          amount:parseFloat(sessionStorage.getItem('amt')*100).toFixed(2),
          currency:sessionStorage.getItem('currency'),
          merchant_id:this.transactionId,
          reference:userdetail.reference,
          address_line_1:this.personalinfo.address1, 
          address_line_2:this.personalinfo.address2,
          city:this.personalinfo.city, 
          state:this.personalinfo.scpr, 
          zip:this.personalinfo.postCode, 
          country:this.personalinfo.country.primaryNumberCode.toUpperCase(),
          // phone_number_code:userdetail.phonecode,
          phone_number:usermobileNumber.number,
          email:this.personalinfo.email,
          payment_ip:userdetail.ipaddress,//"17.0.0.1",
          challenge_indicator:"challenge_requested_mandate"
        }
        this.updatePayment(updateData);
        
        let checkoutData = {
          transactionId:this.transactionId,
          // providerid:"3fa2d222-98b9-402b-bf2e-6a89f152e3db",
          transactionType:"collection_card",
          description:"defefefrf",
          CardNumber:this.cardNumber.replaceAll(/\s/g, ""),
          CardYear:dob[1].split(" ").join("").length == 4 ? parseInt(dob[1].split(" ").join("")) : parseInt(first2Digit + dob[1].split(" ").join("")),
          CardMonth:parseInt(dob[0].split(" ").join("")),
          CardHolderName:this.paybyCard.fullName,
          CardCVV:this.cardCvc,
          currency:sessionStorage.getItem('currency'),
          amount:parseFloat(sessionStorage.getItem('amt')).toFixed(2), //parseFloat(sessionStorage.getItem('amt')*100).toFixed(2),
          email:this.personalinfo.email,
          address:this.personalinfo.address1,
          city:this.personalinfo.city,
          state:this.personalinfo.scpr,
          zip:this.personalinfo.postCode,
          billingCountry:this.personalinfo.country.primaryNumberCode.toUpperCase(),
          autoCapture: true,
        }
        
        this.$http
          .post(
            this.$namecheckurl + "collection/authorize",
            checkoutData,{
              headers: {
                //'x-tradenameid': '610465b6-1a8f-4b6f-a42d-d044175260f6',
                'x-mode': 'dev',
                'x-usertype': 'api',
                'Content-Type': 'application/json',
                'Authorization' : 'Basic NGRjZjFjMmVjMDA2NDU2N2EwN2U1OWI1ZjA5NjU5MDI6NDcyYmFkZjc1MWI0NGQ1Mzg4NDM5NjIyYTdkNTkwOGI='
              },
            }
          )
          .then((response) => {
            if(response.data.data?.threeDSMethodData && response.data.data?.['3DSMethodData']){
              this.ecompayurl = response.data.data.url;
              this.DSMethodData = response.data.data?.['3DSMethodData'];
              this.threeDSMethodData = response.data.data?.threeDSMethodData;
              this.$refs.tdsMmethodForm.submit();

              // Close the iframe and send a request  after 10 seconds
              setTimeout(() => {
                const iframe = document.getElementById("tdsMmethodTgtFrame");
                if (iframe) {
                  iframe.parentNode.removeChild(iframe);
                }
                this.checkIframe();
              }, 10000);
            }else if(response.data.data?.threeDSSessionData && response.data.data?.creq){
              this.ecompayurl = response.data.data.url;
              this.ecompaycreq = response.data.data?.creq;
              this.ecompaythreedsession= response.data.data?.threeDSSessionData;
              this.$refs.threedsform.submit();
            }else{
              window.location.href = response.data.data.url;
            }
          })
          .catch((error) => {
            this.btnDesabled = false;
            console.log(error.response);
          });
      }
    },

    checkIframe() {
      let transaction = {
        transactionId: this.transactionId,
      }
      this.$http.post(this.$namecheckurl + "collection/check_iframe",transaction,
          {
            headers: {
              // "x-tradenameid": "037a7a77-7365-477a-a842-2844438b521f",
              "x-mode": "dev",
              "x-usertype": "api",
              "Content-Type": "application/json",
              Authorization:"Basic NGRjZjFjMmVjMDA2NDU2N2EwN2U1OWI1ZjA5NjU5MDI6NDcyYmFkZjc1MWI0NGQ1Mzg4NDM5NjIyYTdkNTkwOGI=",
            },
          }
        )
        .then((response) => {
          // Check for positive response and required fields for form submission
          if (response.data &&response.data.data.creq &&response.data.data.threeDSSessionData) {
            this.ecompayurl = response.data.data.url;
            this.ecompaycreq = response.data.data.creq;
            this.ecompaythreedsession = response.data.data.threeDSSessionData;

            // Submit the form
            this.$refs.threedsform.submit();
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },

    updatePayment(paymentReq) {
      // console.log(paymentReq);
      let paymentData = {
        paymentrequest: paymentReq,
        paymentproviderid: null
      };
      // console.log(paymentData);
      this.$http
        .put(this.$standappurl + "payment/"+this.transactionId, paymentData)
        .then((res) => {
          console.log(res.data.code);
          // this.callpaymentdetail();
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
    },

    enterOnlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },

    checkNameFormat(e, fieldName, elementName, elementIndex) {
      let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
      let charLength = e.target.value.length;
      if (/^[A-Za-z'-\s]+$/.test(char)) {
        var splitStr = e.target.value.split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          if (splitStr[0] != "")
            splitStr[i] =
              splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        this.paybyCard[fieldName] = splitStr.join(" ");
        if (charLength >= 2 && charLength <= 35 && splitStr[0] != "") {
          this.fieldErrorMsg = "";
          this.fieldName = "";
          if (this.errorArr.some((obj) => obj.index == elementIndex)) {
            this.errorArr[elementIndex].fieldName = "";
            this.errorArr[elementIndex].errorMessage = "";
            this.errorArr[elementIndex].index = elementIndex;
            //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
          }
          return true; // Match with regex
        } else {
          if (this.errorArr.some((obj) => obj.index == elementIndex)) {
            this.errorArr[elementIndex].fieldName = fieldName;
            this.errorArr[elementIndex].errorMessage =
              "Please provide minimum two characters without spaces at start.";
            this.errorArr[elementIndex].index = elementIndex;
            //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
          }
        }
      } else {
        this.paybyCard[fieldName] = char;
        this.fieldErrorMsg = "Please provide valid value for " + elementName;
        if (!this.errorArr.some((obj) => obj.index == elementIndex)) {
          for (let i = 0; i < elementIndex; i++) {
            if (this.errorArr[i] === undefined) {
              this.errorArr.push({ fieldName: "", errorMessage: "", index: i });
            }
            //if(this.errorArr[elementIndex].fieldName == '')
          }
          this.errorArr.push({
            fieldName: fieldName,
            errorMessage: this.fieldErrorMsg,
            index: elementIndex,
          });
        } else if (this.errorArr.some((obj) => obj.index == elementIndex)) {
          this.errorArr[elementIndex].fieldName = fieldName;
          this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
          this.errorArr[elementIndex].index = elementIndex;
        } else {
          // do nothing
        }

        e.preventDefault(); // If not match, don't add to input text
      }
    },
    prefill: function (ccNum) {
      this.cardNumber = ccNum;
      this.$cardFormat.setCardType({
        currentTarget: this.$refs.cardNumInput,
        value: ccNum,
      });
    },
    getBrandClass: function (brand) {
      let icon = "";
      brand = brand || "unknown";
      let cardBrandToClass = {
        visa: "fa fa-cc-visa",
        mastercard: "fa fa-cc-mastercard",
        amex: "fa fa-cc-amex",
        discover: "fa fa-cc-discover",
        diners: "fa fa-cc-diners-club",
        jcb: "fa fa-cc-jcb",
        unknown: "fa fa-credit-card",
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }
      return icon;
    },

    setPrimaryNumberCountryCode(defaultCountry) {
      // console.log(defaultCountry);
      // this.$refs.telInput.choose(defaultCountry.primaryNumberCode);
      this.paybyCard.currency.name = defaultCountry.currency; //defaultCountry.primaryNumberCode == "GH" ? "GHS" : "GBP";
    },

    createLog(transactionid, pagename){
      let addLog = {
        transactionid : transactionid,
        pagename : pagename
      }
      this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
          console.log(response.data.code);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mobileInputValidate(phoneObject) {
      this.mobileInputError = phoneObject.valid;
    },
    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
      if (/^\W$/.test(event.key)) {
        event.preventDefault();
      }
    },
    setUserPrimaryNumberCountryCode(defaultCountry) {
      this.$refs.telInputUser.choose(defaultCountry.primaryNumberCode);
    },
    async getUserCountryData() {
      try {
        const response = await this.$http.get(this.$standappurl + "countries");
        // console.log(response);
        this.usercountrysOptions = [];
        // JSON responses are automatically parsed.
        response.data.map((item) => {
          if(item.isocode.toLowerCase() === sessionStorage.getItem('countrycode').toLowerCase()){
            this.personalinfo.country = {
              id:item.countryid,
              name: item.countryname, 
              flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
              primaryNumberCode:item.isocode,
              alpha2:item.alpha2,
              alpha3:item.alpha3,
              currency:item.currencycode,
              mobilecode: item.mobilecountrycode
            }
          }
          let countryData = {
            id:item.countryid,
            name: item.countryname, 
            flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
            primaryNumberCode:item.isocode,
            alpha2:item.alpha2,
            alpha3:item.alpha3,
            currency:item.currencycode,
            mobilecode: item.mobilecountrycode
          }
          this.usercountrysOptions.push(countryData);
        })
      } catch (error) {
        console.log(error);
      }
    },  
    setpersonalInfo(){
      const userdetail = JSON.parse(sessionStorage.getItem("userData"));
      // console.log('vvv',userdetail);
      let userphoneNumber = '';
      if(userdetail && userdetail.phonenumber){
        userphoneNumber = parsePhoneNumber(userdetail.phonenumber, sessionStorage.getItem('countrycode').toUpperCase());
      }
      
      // console.log(userphoneNumber, sessionStorage.getItem('countrycode').toUpperCase());
      this.personalinfo.firstName = userdetail.firstname;
      this.personalinfo.middleName = userdetail.middlename;
      this.personalinfo.lastName = userdetail.lastname;
      this.personalinfo.email = userdetail.email;
      this.personalinfo.mobile = userphoneNumber?userphoneNumber.nationalNumber:'';
      this.personalinfo.address1 = userdetail.address1;
      this.personalinfo.address2 = userdetail.address2;
      this.personalinfo.city = userdetail.city;
      this.personalinfo.scpr = userdetail.state;
      this.personalinfo.postCode = userdetail.zip;
      this.userMobileNumber = {
        mode: "auto",
        defaultCountry: sessionStorage.getItem('countrycode')?sessionStorage.getItem('countrycode').toUpperCase():'GH',
        autoFormat: true,
        dropdownOptions: {
          showFlags: true,
          disabled: true,
          showDialCodeInSelection: true,
        },
      };
      this.usermobilestatus = true;
    },
    updatepersonalinfo(){
      const usermobileNumber = parsePhoneNumber(this.personalinfo.mobile, this.personalinfo.country.primaryNumberCode);
      
      let personalinfoData = {
        firstname: this.personalinfo.firstName,
        lastname: this.personalinfo.lastName,
        email:this.personalinfo.email,
        phonenumber:usermobileNumber.number,
        country: this.personalinfo.country.primaryNumberCode,
        address1:this.personalinfo.address1,
        city:this.personalinfo.city,
        state:this.personalinfo.scpr,
        zip:this.personalinfo.postCode
      };
      if(this.personalinfo.middleName){
        personalinfoData.middlename = this.personalinfo.middleName
      }
      if(this.personalinfo.address2){
        personalinfoData.address2 = this.personalinfo.address2
      }
      // console.log(personalinfoData);
      this.$http
        .put(this.$standappurl + "payment/personalinfo/"+this.transactionId, personalinfoData)
        .then((res) => {
          console.log(res.data.code);
          // this.callpaymentdetail();
        })
        .catch((error) => {
          console.log(error.response.data.code);
        });
    },

    loqateAddress: function($event,fieldType){
      let searchValue = $event.target.value;
      this.searchText = searchValue;
      this.fieldType = fieldType;

      const username = this.$namecheckuser;
      const password = this.$namecheckpwd;
      const token = Buffer.from(`${username}:${password}`).toString("base64");

      var that = this;
      setTimeout(function() {
          if(searchValue == ''){
              that.addressData = '';
          } else {
              let findAddressData = {
                  address : searchValue,
                  country : that.personalinfo.country.primaryNumberCode.toUpperCase(),
                  type : "Address",
                  container_id : ""
              }
              // console.log(findAddressData);
              that.$http.post(that.$locatebaseurl+'find-address/', findAddressData,{
                headers:{Authorization:`Basic ${token}`}
              }).then((res) => {
                  if(res.data.code == '0021-000'){
                      that.addressData = res.data.Items;
                  } else {
                      that.addressData = '';
                  }
              }).catch((error) => {
                  console.log(error);
                  that.addressData = '';
                  // this.apiErrorCode = error.response.data.code;
              })
          }
      }, 2000);
    },
    SelectAddress(id,type){
      const username = this.$namecheckuser;
      const password = this.$namecheckpwd;
      const token = Buffer.from(`${username}:${password}`).toString("base64");
      var that = this;
      if(type != 'Address') {
        setTimeout(function() {
          let findAddressData = {
            address : that.searchText,
            country : that.personalinfo.country.primaryNumberCode.toUpperCase(),
            type : type,
            container_id : id
          }
          // console.log(findAddressData);
          that.$http.post(that.$locatebaseurl+'find-address/', findAddressData,{
            headers:{Authorization:`Basic ${token}`}
          }).then((res) => {
            if(res.data.code == '0021-000'){
              that.addressData = res.data.Items;
            } else {
              that.addressData = '';
            }
          }).catch((error) => {
            console.log(error);
            that.addressData = '';
          })
        }, 2000);
      } else {
        let retrieveAddressData = {
          id : id
        }
        that.$http.post(that.$locatebaseurl+'retrieve-address/', retrieveAddressData,{
          headers:{Authorization:`Basic ${token}`}
        }).then((res) => {
          if(res.data.code == '0020-000'){
            let selectedAddress = res.data.Items[0];
            that.personalinfo.address1 = selectedAddress.Line1;
            that.personalinfo.address2 = selectedAddress.Line2;
            that.personalinfo.city = selectedAddress.City;
            that.personalinfo.scpr = selectedAddress.Province;
            that.personalinfo.postCode = selectedAddress.PostalCode;
            that.searchText = '';
            that.fieldType = '';
            that.addressData = '';
            if(selectedAddress.Line1 === '') {
              that.queryAddress1 = false;
            }
            if(selectedAddress.City === '') {
              that.queryCity = false;
            }
            if(selectedAddress.PostalCode === '') {
              that.queryZip = false;
            }
            if(selectedAddress.Province === '') {
              that.queryProvince = false;
            }
          } 
        }).catch((error) => {
          console.log(error);
          // this.apiErrorCode = error.response.data.code;
        })
      }
    },

    capitalizeFirstLetter(event,fieldName){
      var splitStr = event.target.value.split(' ');
      if(splitStr[0] == ''){
        this.locatefieldName = fieldName;
        this.fieldErrorMsg = 'Please provide minimum two characters.';
      } else {
        this.fieldErrorMsg = '';
        this.locatefieldName = '';
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        this.personalinfo[fieldName] = splitStr.join(' ');
      }
    },
  },
  computed: {
    cardBrandClass() {
      return this.getBrandClass(this.cardBrand);
    },
  },
  watch: {
    // handle auto-focus to next field
    // Note: since CVC can be 3 OR 4 digits we don't watch it
    cardNumber: function (val) {
      if (this.$cardFormat.validateCardNumber(val)) {
        // this.$refs.cardExpInput.focus();
      }
    },
    cardExpiry: function (val) {
      if (this.$cardFormat.validateCardExpiry(val)) {
        this.$refs.cardCvcInput.focus();
      }
    },

    checkout_country: function (result) {
      this.setPrimaryNumberCountryCode(result);
    },

    ecompaycreq() {
      this.submit3DSForm();
    },
    ecompaythreedsession() {
      this.submit3DSForm();
    },
    DSMethodData() {
      this.submitTdsMmethodForm();
    },
    threeDSMethodData() {
      this.submitTdsMmethodForm();
    },

    // merchant_details: function (data) {
    //   this.merchantdata = data;
    // }
  },
  onMounted() {
    this.$refs.cardNumInput.focus();
  },
  mounted() {
    if(sessionStorage.getItem('settranid')){
      this.transactionId = sessionStorage.getItem('settranid');
      // console.log(this.$emit(this.merchant_details.amount));
      // this.countryID = sessionStorage.getItem('country');
      this.getUserCountryData();
      this.setpersonalInfo();
      this.createLog(sessionStorage.getItem('settranid'), 'Pay By Card');
    }
  }
};
</script>
<style scoped>
@import "~vue-tel-input/dist/vue-tel-input.css";
</style>
<style>
.fa-lg {
  font-size: 30px !important;
}
</style>